<template>
	<div class="cs-block cs-blog-post-content">
		<div class="cs-base-block">
			<div class="container">
				<CSBlogPostContent :data="data" />
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "BlogPostContent",
	components: {
		CSBlogPostContent: () => import("../components/CSBlogPostContent.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
    computed: computed('BlogPostContent')
}
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";

.cs-blog-post-content {
    background: #fff;
    ::v-deep {
        .utils-created-updated {
			flex-wrap: wrap;
			gap: 5px;
			.updated {
				color: $secondary_text_color;
			}
			.updated:first-child {
				margin-left: 0;
				svg {
					margin-right: 5px;
					path {
						fill: $bg_dark;
					}
				}
				span {
					margin-left: 5px;
				}
			}
			.updated:last-child {
				margin-left: 23px;
				font-weight: 600;
				background-color: $theme_color;
				padding: 5px;
				border-radius: 3px;
			}
		}

        .cs-text {
            color: $primary_text_color;

            * {
                color: $labels_color;
            }
            p {
                color: $primary_text_color;
                img {
                    width: 100%;
                    object-fit: cover;
                    height: 300px;
                    margin: 10px 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cs-blog-post-content-card {
            background-color: $theme_color !important;
            padding: 30px 20px;
            border-radius: 0;
            &:last-child {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
            }
            h6 {
				@include small-title;
			}
            @media (max-width: 991px) {
                margin-top: 20px;
            }
        }

        .cs-blog-post-content-linked {
            margin-bottom: 15px;
            text-decoration: none;
            color: #101922;
            display: block;

            &:last-child {
                margin-bottom: 0px;
            }

            h4 {
                @include small-title;
                margin-bottom: 0px;
                margin-top: 15px;
            }
        }

        .cs-blog-post-content-tag {
            @include tag-btn;
            background-color: #fff;
            border: none;
        }
    }
}
</style>
